<template>
  <div class="Login__Page">
    <div class="is-flex is-flex-direction-row-reverse">
      <div class="Login__Page__Figure">
        <div class="img-responsive">
          <img src="@/assets/login.svg" alt="" />
        </div>
      </div>
      <div class="
          Login__Page__Form
          p-6
          is-flex is-flex-direction-row is-align-items-center
        ">
        <section class="is-flex is-flex-direction-column">
          <h1>ברוכים הבאים לניהול הסימולטור 👋</h1>
          <h3 class="subtitle">
            להתחברות למערכת יש להקליד את שם המשתמש וסיסמא
          </h3>
          <form @submit.prevent="login">
            <b-field label="אימייל">
              <b-input placeholder="" v-model="email" type="email" validation-message="כתובת המייל שהוזנה אינה תקנית"
                icon-right="close-circle" icon-right-clickable @icon-right-click="clearIconClick">
              </b-input>
            </b-field>

            <b-field label="סיסמא">
              <b-input type="password" v-model="password" placeholder="" password-reveal>
              </b-input>
            </b-field>
            <b-message type="is-danger" v-if="invalidCredentials && !userLocked">
              שם משתמש או סיסמא אינם נכונים.
            </b-message>
            <b-message type="is-danger" v-if="userLocked">
              חלו יותר מדי טעויות בזיהוי - המשתמש ננעל לדקה
            </b-message>
            <b-button :loading="loading" native-type="submit" type="is-primary">התחברות למערכת</b-button>
            <!-- <router-link :to="{ name: 'Forgot', query: { email } }">שכחתי סיסמה</router-link> -->
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      invalidCredentials: false,
      loading: false,
      userLocked: false,
    };
  },
  methods: {
    searchIconClick() {
      alert("You wanna make a search?");
    },
    clearIconClick() {
      this.email = "";
    },
    login() {
      this.loading = true;
      this.invalidCredentials = false;
      this.$auth
        .login({
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (this.isQuoter) {
            this.$router.replace({ name: "AdminQuotes" });
          } else {
            this.$router.replace({ name: "admin" });
          }
        })
        .catch((err) => {
          if (
            err.response.data.error_description ===
            "The user name or password is incorrect."
          ) {
            this.invalidCredentials = true;
          }
          if (
            err.response.data.error_description ===
            "User locked out due to too many failed attempts. Try again later."
          ) {
            this.userLocked = true;
          } else {
            this.userLocked = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    isQuoter() {
      return this.$store.state.auth.role === "Quoter";
    }
  }
};
</script>

<style lang="scss">
.Login__Page {
  &>div {
    height: 100vh;
  }
}

.Login__Page__Form {
  flex: 0 1 600px;

  section {
    width: 100%;

    h1 {
      font-size: 25px;
      text-align: right;
    }

    .subtitle {
      margin: 5px 0 20px 0;
      font-size: 17px;
      color: #7b7b7b;
    }

    .label {
      font-size: 15px;
      font-weight: normal;
      color: #505050;

      &:not(:last-child) {
        margin: 0;
      }
    }

    input {
      direction: ltr;
    }

    button {
      margin: 10px 0;
      width: 100%;
    }

    .message-body {
      border-width: 0 4px 0 0;
      padding: 0.6em 0.6em;
    }
  }
}

.Login__Page__Figure {
  flex: 1 1 300px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .Login__Page__Figure {
    display: none !important;
  }
}
</style>
